<!--
Login page
New feature:
Create a /LiveUser object with the uid of account if it does not exist.
-->
<template>
  <div>
    <b-alert variant="danger" :show="$app.devServerCorsCoop">
      This site is running with 'Cross-Origin-Opener-Policy': 'same-origin', for development. This may cause problems
      with authentication. Google Auth will not work.
    </b-alert>
    <div class="d-flex flex-column text-center">
      <div class="m-4">
        <a :href="$app.productURL"><img :src="$app.productLogoSmall" width="180" /></a><br />
        <b-badge v-if="$app.environment" variant="success">{{ $app.environment }}</b-badge>
      </div>
      <p>{{ $app.tagLine }}</p>
      <div v-if="!account" id="firebaseui-auth-container"></div>
      <div v-if="account">
        <div class="m-4">
          <div class="m-5">Logged in as {{ account.displayName }} ({{ account.email }})</div>
          <b-button variant="primary" @click="$router.push($route.meta.nextRoute || '/fnstats/charts')">Start</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLog } from '@/services/log';
let log = getLog("fnstats-login");
// Import Firebase and FirebaseUI
import { firebase } from '@/services/firebase';
import "firebase/compat/app";
import "firebase/compat/auth";
let auth = firebase.auth();
import * as firebaseui from 'firebaseui';
import "firebaseui/dist/firebaseui.css";
import { getBrowser } from '@/services/utils';

export default {
  name: 'Login',
  data() {
    return {
      account: null,
    }
  },
  mounted() {
    this.setupUI();

    this.AuthStateChangedListener = auth.onAuthStateChanged(async account => {
      log.log("onAuthStateChanged", account);
      this.account = account;
    });
    this.IdTokenChangedListener = auth.onIdTokenChanged(async account => {
      this.accountToken = account ? await account.getIdTokenResult() : null;
      log.log("onIdTokenChanged", this.accountToken);
    });
  },
  methods: {
    // Authentication and claims
    setupUI() {
      let signInOptions = [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ]
      if (!this.$app.signInOptionsNoGoogle && !getBrowser().includes("safari"))
        signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
      log.log("signInOptions=", signInOptions);

      // FirebaseUI config.
      let uiConfig = {
        signInSuccessUrl: window.location.href,
        callbacks: {
          signInSuccessWithAuthResult: () => {
            log.log("signInSuccessWithAuthResult");
            this.upgradeAuth();
            // Do not automatically redirect.
            return false;
          },
        },
        signInOptions,
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/terms-of-use',
        // Privacy policy url/callback.
        privacyPolicyUrl: () => {
          window.location.assign('/privacy-policy');
        }
      };

      //log.log("Firebaseui:", firebaseui);
      // Initialize the FirebaseUI Widget using Firebase.
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth());
      }
      // The start method will wait until the DOM is loaded.
      ui.start('#firebaseui-auth-container', uiConfig);
    },
  }
};
</script>
